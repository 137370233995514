.pbver,
#pb_package {
  display: none;
}

#app-pb {
  .container {
    width: 100%;
    display: block;
  }

  .pricediv {
  }

  #priceloading {
    float: right;
    padding-left: 7px;
  }

  #partnumber {
    float: left;
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
  }

  #pricing {
    float: right;
    background: #333;
    padding: 5px 7px;
    font-size: 11px;
    text-transform: uppercase;
    font-family: Montserrat;
    letter-spacing: 1px;
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.47);
  }

  #priceheading,
  .price {
    display: inline;
  }
}

.pb-initialized {
  .layout-region-product-footer {
    background: #fff;
    width: calc(50% - 5px);
    margin: -16px 0 0;
    padding: 0 40px;
  }

  .layout-region-product-footer__content {
    margin: 0 0 40px;
    padding: 0;
  }

  #content #block-midtownarchitects-content {
    overflow: visible;
  }
}

.path-create.pb-initialized #content {
  padding-top: 0;
  margin-top: 0;
  background: #eee;
  overflow: hidden;
  > .container {
    width: 100%;
    margin-left: -5px;
  }

  #block-midtownarchitects-content {
    background: none;
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }

  > .container {
    padding-top: 0;
    margin-top: 0;
  }
}

// @media (min-width: 1280px) {
.minWidth1280 {
  .path-create.pb-initialized:not(#bock-role_repview)
    #content
    #block-midtownarchitects-content {
    width: 100% !important;
  }
}
// }

.page-return,
.col-feature-faux .container,
.ccn-product-form-surround {
  margin: 0 auto;
  position: relative;
}

// @media only screen and (max-width: 1499px) and (min-width: 980px) {
//   body:not(#bock-role_repview) {
//     .page-return, .col-feature-faux .container, .ccn-product-form-surround {
//       width: 920px;
//     }
//   }
// }

// @media only screen and (min-width: 1500px) {
//   body:not(#bock-role_repview) {
//     .page-return, .col-feature-faux .container, .ccn-product-form-surround {
//       width: 1230px;
//     }
//   }
// }

// @media only screen and (max-width: 1699px) and (min-width: 980px) {
//   body#bock-role_repview {
//     .page-return, .col-feature-faux .container, .ccn-product-form-surround {
//       width: 920px;
//     }
//   }
// }

// @media only screen and (min-width: 1700px) {
//   body#bock-role_repview {
//     .page-return, .col-feature-faux .container, .ccn-product-form-surround {
//       width: 1230px;
//     }
//   }
// }

// .col-feature-surround-faux {
//   position: relative;
//   margin-top: -45px;
//   width: 100%;
//   margin-bottom: 40px;
//   z-index: 4;
// }
//
// .col-feature-faux {
//   width: 100%;
//
//   .next-to-icon {
//     width: inherit;
//     max-width: 800px;
//     border-left: 0;
//     padding-left: 8px;
//   }
//
//   h2 {
//     margin-top: 5px;
//   }
//
//   p {
//     font-style: italic;
//     font-size: 16px;
//     line-height: 29px;
//     padding-bottom: 5px;
//   }
// }
//
// @media (min-width: 700px) {
//   .pb-initialized .col-feature p {
//     font-style: italic;
//     font-size: 17px;
//     line-height: 35px;
//     max-width: 80%;
//     padding-bottom: 5px;
//   }
// }

// @media (max-width: 1280px) {
.maxWidth1280 {
  #app-pb .container {
    /*max-width: 800px;*/
    width: 100%;
  }
}

// }

// @media (max-width: 992px) {
.maxWidth992 {
  .path-create.pb-initialized #content {
    #block-midtownarchitects-content {
      width: 100%;
      margin: 0 auto;
    }

    > .container {
      width: 100%;

      .block-page-title-block {
        margin: 0 30px;
      }
    }
  }

  #app-pb .container {
    width: 100%;
  }

  .three-container-inner {
    width: 100% !important;
  }

  .triggerThreeExpand,
  #toggleThreeExpand {
    display: none !important;
  }
}

// }

// @media (max-width: 767px) {
.maxWidth767 {
  #app-pb .container {
    max-width: 215px;
    max-width: none;
  }
}

// }

.bkPb-MuiExpansionPanelSummary-content {
  margin: 0;
}

.bkPb-MuiTypography-body1 {
  font-family: inherit !important;
}

#app-pb {
  .bkPb-MuiPaper-root.bkPb-MuiPaper-elevation1.bkPb-MuiPaper-rounded {
    margin-top: 0;
    box-shadow: none;
    border-radius: 0;
  }

  .bkPb-MuiTable-root {
    width: 100% !important;
    min-width: 0;
  }

  .bkPb-MuiTableHead-root {
    background: #fff;
    border: 2px solid #000;
  }

  .bkPb-MuiTableCell-root {
    font-family: "Raleway", "Open Sans", Arial, Helvetica, sans-serif !important;
    &.bkPb-MuiTableCell-head {
      padding: 15px 8px;
      line-height: 12px;
      font-family: Montserrat !important;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 9px;
      font-weight: 800;
      color: #333;
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.4);
    }
  }
}

.bkPb-MuiTypography-h6,
.bkPb-MuiDialogContentText-root {
  font-family: "Raleway", "Open Sans", Arial, Helvetica, sans-serif !important;
}

.bkPb-MuiDialog-container {
  // * {
  //   font-family: "Raleway", "Open Sans", Arial, Helvetica, sans-serif !important;
  // }

  .bkPb-MuiTypography-h6 {
    line-height: 1.25;
    color: #2b2b2b;
    display: block;
    position: relative;
    padding: 0;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
  }

  .bkPb-MuiDialogTitle-root {
    padding: 30px 24px 10px;
  }

  .bkPb-MuiDialogContent-root {
    font-size: 14px;
    font-style: italic;
    line-height: 25px;
    padding-bottom: 0;

    * {
      font-size: 14px;
      font-style: italic;
      line-height: 25px;
      padding-bottom: 0;
    }
  }

  .bkPb-MuiDialogActions-root {
    padding: 0 15px 15px;
  }
}

#app-pb .bkPb-MuiSnackbarContent-root {
  padding: 8px 16px;
  min-height: 50px;
  background-color: #79b;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #fff;
}

// @media (min-width: 1340px) {
//   #bock-role_repview #app-pb .bkPb-MuiSnackbarContent-root {
//     /* @comment: on BockLighting.com only */
//   }
// }

.fullscreen-enabled .bkPb-MuiSnackbarContent-root {
  margin-left: 0 !important;
}

#app-pb {
  .bkPb-MuiTypography-body2 {
    font-family: "Raleway", "Open Sans", Arial, Helvetica, sans-serif;
  }

  .bkPb-MuiSnackbarContent-action {
    padding-left: 8px;
  }
}

.bkPb-MuiTab-wrapper img {
  max-width: 50px;
}

.bkPb-MuiTabs-indicator {
  background-color: transparent !important;
  height: 0 !important;
  display: none;

  &:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
  }
}

#app-pb .menu-holder {
  width: auto;
  float: left;
}

.menu-holder > div {
  z-index: 10000;
}

#app-pb {
  .menu-holder > button.bkPb-MuiButton-text {
    float: right;
    z-index: 1;
    border: 1px solid rgba(255, 255, 255, 0.9);
    font-size: 11px;
    text-transform: uppercase;
    font-family: Montserrat;
    letter-spacing: 1px;
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.9);
    transition: 0.2s ease all;
    display: inline;
    padding: 5px 9px;

    &:hover {
      color: rgba(255, 255, 255, 0.47);
      border: 1px solid rgba(255, 255, 255, 0.47);
    }

    .fa {
      padding-left: 4px;
    }
  }

  #menu-list-grow {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
    border-radius: 3px;
    margin-top: 15px;

    > .bkPb-MuiList-root:after {
      top: -20px;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 10px;
      margin-left: -10px;
    }

    .bkPb-MuiMenuItem-root {
      min-width: 190px;
      padding-top: 13px;
      line-height: 15px;
      min-height: 52px;
      position: relative;
      padding-bottom: 23px;
      z-index: 1;
      font-family: "Raleway", "Open Sans", Arial, Helvetica, sans-serif;

      &:after {
        content: "\f058";
        font-family: "BKAPP_PB_FA";
        right: 10px;
        top: 20px;
        font-size: 13px;
        line-height: 11px;
        font-weight: 100;
        position: absolute;
        color: #41b935;
      }
    }

    .ccn-clipboard {
      display: block;
      width: 100%;
      border-radius: 0;
      background: transparent !important;
      text-align: center;
      font-family: Montserrat;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
      padding: 10px 0 8px;
      margin-bottom: -8px;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      transition: all 0.2s ease;

      &:hover {
        color: #888;
      }

      &:active,
      &:focus {
        border-color: #fff;
      }
    }
  }

  .build-code {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 52px;
    padding-left: 15px;
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 25px;
  }
}

.build-section {
  position: absolute;
  bottom: 2px;
  z-index: 0;
  left: 16px;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", Arial, Helvetica,
    sans-serif;
  color: #2b2b2b;
  letter-spacing: 2px;
}

.build-item {
  position: relative;
}

.admin-link {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  background: #000;
  padding: 5px 10px;
  font-size: 8px;
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 1px;
  border-bottom: 0;
  line-height: 10px;

  &:hover {
    border-bottom: 0;
    color: #fff;
  }
}

.triggerThree {
  cursor: pointer;
  text-indent: -9999px;
  width: 80px;
  height: 30px;
  background: #333;
  display: block;
  border-radius: 100px;
  position: absolute;
  z-index: 9;
  left: 10px;
  top: 10px;
}

.triggerThreeExpand {
  cursor: pointer;
  text-indent: -9999px;
  width: 80px;
  height: 30px;
  background: #333;
  display: block;
  border-radius: 100px;
  position: absolute;
  z-index: 9;
  left: 10px;
  top: 10px;
  left: 100px;
}

.triggerThree:after,
.triggerThreeExpand:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 5px;
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

#toggleThreeExpand,
.triggerThreeExpand {
  /* display: none; */
}

input {
  &#toggleThree:checked ~ {
    label.triggerThreeExpand,
    #toggleThreeExpand {
      display: inline-block;
    }
  }

  &#toggleThreeExpand:checked ~ {
    label.triggerThree,
    #toggleThree {
      opacity: 0.1;
    }
  }

  &#toggleThree:checked + label.triggerThree,
  &#toggleThreeExpand:checked + label.triggerThreeExpand {
    background: #bada55;
  }

  &#toggleThree:checked + label.triggerThree:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  &#toggleThreeExpand {
    z-index: 11;
    &:checked {
      + label.triggerThreeExpand:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
      }

      ~ .three-container-inner {
        width: 910px;
      }
    }
  }
}

.triggerThree:active:after,
.triggerThreeExpand:active:after {
  width: 70px;
}

#toggleThree:after {
  content: "3D Off";
  visibility: visible;
  z-index: 1;
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  width: 50px;
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat";
  color: #fff;
  font-weight: 600;
  top: 13px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
  top: 7px;
  height: 17px;
  right: -94px;
}

#toggleThreeExpand:after {
  content: "3D Off";
  visibility: visible;
  z-index: 1;
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  width: 50px;
  font-size: 9px;
  text-transform: uppercase;
  font-family: "Montserrat";
  color: #fff;
  font-weight: 600;
  top: 13px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
  top: 7px;
  height: 17px;
  right: -94px;
  right: -183px;
  content: "Expand";
}

#toggleThree:checked:after {
  content: "3D On";
  visibility: visible;
  right: auto;
  left: 14px;
}

#toggleThreeExpand:checked:after {
  content: "3D On";
  visibility: visible;
  right: auto;
  left: 14px;
  content: "Expand";
  left: auto;
  right: -154px;
}

.triggerThree:focus,
.triggerThreeExpand:focus {
  outline: 0;
}

input[type="checkbox"] {
  &#toggleThree,
  &#toggleThreeExpand {
    visibility: hidden;
  }
}

.three-container-inner {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.overview .three-container-inner {
  width: 100%;
  overflow: hidden;
}

.three-container-inner canvas {
  position: relative;
}

/* Make element after checked checkboxed visible */

input[type="checkbox"]#toggleThree:checked ~ div.three-container-inner {
  opacity: 1;
  transition: opacity ease 0.05s;
}

#three-container {
  position: absolute;
  z-index: 4;
  top: 140px;
  left: 10px;
  width: calc(100% - 20px);
  height: 245px;
  overflow: hidden;
}

/* Begin 3d */

#threejsjson {
  height: 0;
  width: 100%;
  display: block;
}

#scene3d {
  height: 100%;
  width: 100%;
  display: block;
}

/* End 3d */
// @media (min-width: 1500px) {
.minWidth1500 {
  #three-container {
    top: 158px;
  }

  .three-container-inner {
    width: 450px;
  }

  input#toggleThreeExpand:checked ~ .three-container-inner {
    width: 1220px;
  }
}

// }

// @media (max-width: 992px) {
.maxWidth992 {
  #three-container {
    margin: 0 15px;
    width: calc(100% - 50px);
    height: 176px;
  }

  .three-container-inner {
    height: 176px;
  }
}

// }

// @media (min-width: 993px) {
.minWidth993 {
  #app-pb .bkPb-MuiSnackbarContent-root {
    white-space: nowrap;
  }
}

// }

// #app-pb .bkPb-MuiSnackbarContent-root {
//   // display: table;
// }

.bkPb-MuiSnackbar-anchorOriginTopCenter {
  .bkPb-MuiSnackbarContent-message {
    display: table-cell;
    width: calc(100% - 40px);
    vertical-align: middle;
  }

  .bkPb-MuiSnackbarContent-action {
    display: table-cell;
    width: 40px;
    vertical-align: middle;
  }
}

#app-pb .color .ccn-prompt {
  margin-bottom: 60px;
}

.bkPb-MuiSnackbar-anchorOriginTopCenter {
  .bkPb-MuiSnackbarContent-root {
    padding: 12px 20px 14px;
  }

  .bkPb-MuiSnackbarContent-message {
    width: 90%;
  }

  .bkPb-MuiSnackbarContent-action {
    width: 10%;
  }
}

// @media (max-width: 1500px) {}

// @media (max-width: 1200px) {
.maxWidth1200 {
  #app-pb .bkPb-MuiSnackbarContent-root,
  .path-create.pb-initialized #content > .container {
    margin-left: 0;
  }
}

// }

// @media (max-width: 992px) {
.maxWidth992 {
  #app-pb .page-return,
  .ccn-product-form-surround {
    padding: 0 15px;
  }
}

// }

// @media (max-width: 979px) {
.maxWidth979 {
  .pb-initialized .col-feature-surround-faux {
    text-align: center;

    .next-to-icon {
      border-top: 0;
      padding-top: 0;
      padding-left: 0;
    }

    .icon-holder {
      display: none;
    }
  }
}

.maxWidth767 {
  #three-container {
    top: 212px;
  }
}
