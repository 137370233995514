@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;600;700;800&family=Karla:wght@300;400;500;600;700&display=swap");

#app-pb-surround {
  background: radial-gradient(
    ellipse at center top,
    rgb(255, 254, 254) 0%,
    rgb(198, 202, 208) 52%,
    rgb(127, 134, 144) 100%
  ) !important;
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

#pb_root {
  // overflow: hidden;
  position: relative;
}

#app-pb {
  z-index: 1;
  background: radial-gradient(
    ellipse at center top,
    rgb(189, 189, 189) 0%,
    rgb(101, 104, 109) 52%,
    rgb(85, 92, 101) 100%
  );
  min-height: 100vh;
  position: relative;
  // box-shadow: -4px 0 3px rgba(0, 0, 0, 0.1);
  font-family: "Karla", "Open Sans", Arial, Helvetica, sans-serif;

  &.build .three-container-inner,
  &.summary .three-container-inner {
    /* width: 100%;
    left: 0;
    right: 0;
    margin-left: 0; */
    height: 460px;
    /* z-index: 2; */
  }

  > .fullscreen-enabled {
    background: radial-gradient(
      ellipse at center top,
      rgb(189, 189, 189) 0%,
      rgb(101, 104, 109) 52%,
      rgb(85, 92, 101) 100%
    );
    min-height: 100vh;
    position: relative;
    box-shadow: -4px 0 3px rgba(0, 0, 0, 0.1);
    font-family: "Karla", "Open Sans", Arial, Helvetica, sans-serif;
  }
  /* overrides */
  /* MUI DRAWER */
  .bkPb-MuiDrawer-docked > div {
    transition: 0.8s cubic-bezier(0.7, 0.3, 0.1, 1) !important;
    will-change: transform;
  }

  .bkPb-MuiDrawer-paperAnchorDockedRight {
    border: 0 !important;
  }

  .bkPb-MuiSnackbar-root {
    position: absolute !important;
    top: 60px;
    left: 25px;
    font-size: 13px;
  }
  /* SELECT2 */
  .select2-container {
    width: 100% !important;
  }

  .select2-container--default {
    .select2-selection--single {
      .select2-selection__arrow {
        height: 26px;
        position: absolute;
        top: 1px;
        right: 10px;
        width: 20px;
        height: 45px;
        top: 0;

        b {
          border-color: #889 transparent transparent transparent !important;
          border-style: solid !important;
          border-width: 5px 5px 0 !important;
          height: 10px;
          left: 50%;
          margin-left: -4px !important;
          margin-top: -2px !important;
          position: absolute;
          top: 50%;
          width: 10px;
          z-index: 100000;
        }
      }
    }

    &.select2-container--open {
      .select2-selection__arrow {
        b {
          transform: rotate(180deg);
          top: 37%;
        }
      }
    }
  }

  .select2-container--open .select2-dropdown {
    width: 205px;
    background-color: #4b4b4b;
    border: 0;
    border-radius: 0;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-weight: 600;
  }

  .select2-results__option {
    padding: 6px 6px 6px 16px;
  }

  .select2-container--default {
    .select2-results__option--highlighted[aria-selected] {
      background-color: #444;
    }

    .select2-results__option {
      &[aria-disabled="true"] {
        display: none;
      }

      &[aria-selected="true"] {
        background-color: #6d6d6d;
      }
    }

    .select2-search--dropdown .select2-search__field {
      border: 1px solid rgba(255, 255, 255, 0.6);
      color: #fff;
      font-size: 14px;
      padding: 10px 11px;
      font-weight: 600;
    }
  }

  .select2-container--open {
    background: #444;
  }

  .select2-container--focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
  }

  .select2-results__options {
    line-height: 25px;
  }

  .nav-part .select2-container--default .select2-selection--single,
  .part-selectors .select2-container--default .select2-selection--single {
    height: 100%;
    background-color: unset;
    border: unset;
    border-radius: unset;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .nav-part
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered,
  .part-selectors
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    height: 100%;
    padding: 9px 15px;
    line-height: 28px;
  }

  .nav-part
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: rgba(255, 255, 255, 0.6);
    line-height: 45px;
    padding: 0 8px 0 13px;
  }

  .select2-container--default
    .select2-results__option--highlighted[aria-selected] {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .select2-container--open {
    .select2-dropdown {
      border-radius: 5px;
      overflow: hidden;
    }

    .select2-dropdown--above {
      top: -10px;
    }

    .select2-dropdown--below {
      top: 10px;
    }
  }
  /* @MaterialUI Tabs */
  .ccn-tabs {
    display: block !important;
    overflow: visible !important;

    .bkPb-MuiTabs-fixed,
    .bkPb-MuiTabs-flexContainer {
      display: block !important;
      overflow: visible !important;
    }
  }

  .bkPb-MuiTab-root {
    font-family: "Karla", "Open Sans", Arial, Helvetica, sans-serif !important;
  }

  .tab-header {
    &.bkPb-MuiTabs-root,
    .bkPb-MuiTabs-fixed {
      overflow: visible !important;
    }
  }
  /* @MaterialUI Speeddial */
  .bkPb-MuiSpeedDial-fab {
    background-color: rgba(255, 255, 255, 0.95) !important;
    color: #333 !important;
  }

  .bkPb-MuiSpeedDialAction-staticTooltipLabel {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    letter-spacing: -1px !important;
    white-space: pre;
    padding: 5px 10px !important;
    background: rgba(255, 255, 255, 0.3) !important;
    color: #fff !important;
    transition: all ease 0.2s !important;
  }

  .bkPb-MuiSpeedDialAction-staticTooltip:hover
    > .bkPb-MuiSpeedDialAction-staticTooltipLabel {
    color: #333 !important;
    background: rgba(255, 255, 255, 0.6) !important;
  }
  /* end overrides */
  .mb20 {
    margin-bottom: 20px !important;
  }

  .go:hover,
  .grid-item:hover,
  .inline-action:hover,
  .pane-stage:hover,
  .redo:hover,
  .triggerDrawer:hover,
  .triggerDrawerSurround:hover {
    cursor: pointer;
  }

  .go.back,
  .redo.back {
    &::after {
      transition: all 0.2s ease 0s;
      will-change: left, padding;
    }

    &:not(.header-nav-action):not(._da):hover::after {
      left: -10px;
      padding-right: 0;
    }
  }

  .scrollbar-container {
    padding: 25px 20px;
  }

  .scrollbar-container.locked {
    overflow: hidden !important;
    position: unset !important;

    .triggerDrawer {
      display: none !important;
    }
  }

  #three,
  .partnumberv3 #three {
    height: 430px;
    width: 100%;
  }
  /* ????? */
  #content {
    padding: 0;
  }
  /* ????? */
  .app-overlay-bottom {
    overflow: hidden;
  }

  .app-overlay-bottom-scroll .intro h2 {
    font-size: 40px;
    text-shadow: 0 5px 5px rgba(0, 0, 0, 0.08);
  }

  #app-nav {
    background: #fff;
    top: 0;
    right: 0;
    overflow: hidden;
    position: relative;
  }

  #app-nav-container {
    height: 100%;
  }

  .c1 {
    /* Black */
    color: #333;
  }

  .c2 {
    /* White */
    color: #fff;
  }

  .c3 {
    /* Red */
    color: #d9534f;
  }

  .c4 {
    /* Blue */
    color: #779abc;
  }

  .c5 {
    /* Green */
    color: #72bb72;
  }

  .app-pane {
    border-radius: 7px;
    box-shadow:
      0 3px 20px 0 rgba(0, 0, 0, 0.08),
      inset 0 0 50px 0 rgba(255, 255, 255, 0.3),
      inset 0 0 0 1px rgba(0, 0, 0, 0.02);
    /*border: 1px solid rgba(255, 255, 255, 0.14);*/
    /* position: absolute; */
    background: #f1f1f1cf;
    overflow: hidden;
    z-index: 9999;
  }

  .action-price {
    width: auto;
    padding: 0 28px;
    border: 1px solid;
    box-shadow: none;
    border-radius: 5px 0 0 5px;
    background: rgba(255, 255, 255, 0.25);
    border-color: rgba(255, 255, 255, 0.2);
    position: relative;
    right: 3px;
  }

  .user-price {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .price-desc {
      position: static;
      top: 0;
      display: block;
      padding: 9px 0 0;
      font-size: 9px;
    }

    .pricing {
      font-weight: bold;
      font-size: 18px;
    }

    .currency {
      display: inline;
      font-size: 10px;
      font-weight: normal;
      position: relative;
      top: -6px;
      padding-right: 1px;
    }
  }

  .pane-actions {
    width: 540px;
    height: 80px;
    bottom: 25px;
    right: 25px;
    display: table;
    border-radius: 5px;
    background: transparent;
    padding: 0;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);
    bottom: 25px;
    left: auto;
    right: 25px;
    transition: all 0.5s cubic-bezier(1, 0.35, 0, 0.8);

    .action {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: #fff;
      /*text-transform: uppercase;*/
      font-weight: 800;
      font-size: 18px;

      .accent {
        display: block;
        font-size: 9px;
        padding: 8px 0 0;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .cta-1 {
      background: #72bb72;
    }
  }

  .app-floater {
    position: absolute;
    bottom: 25px;
    left: 25px;

    .accent {
      color: #fff;
    }

    a {
      padding: 0 0 0 20px;

      &:before {
        content: "\2022";
        position: relative;
        left: -10px;
      }

      &:first-child {
        padding: 0;

        &:before {
          content: "";
        }
      }
    }
  }

  .accent {
    font-family: "Karla";
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .accent-light {
    color: #bbb;
  }

  .accent-md {
    font-size: 12px;
  }

  .accent-sm {
    font-size: 9px;
  }

  .accent-xs {
    font-size: 8px;
  }

  .accent-2 {
    font-style: italic;
    font-size: 11px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.4);
    vertical-align: middle;
    position: relative;
  }

  .nav-head {
    padding: 20px;
    overflow: auto;
    display: table;
    width: 100%;
  }

  .nav-head-content {
    display: table-cell;
    vertical-align: middle;

    .inline-action {
      color: #ccc;
      vertical-align: middle;
    }
  }

  .nav-head-actions {
    display: table-cell;
    vertical-align: middle;
    text-align: right;

    .inline-action {
      font-size: 13px;
      padding: 0 2px 0 0;
    }

    .inline-action-btn {
      border: 1px solid rgba(0, 0, 0, 0.6);
      display: inline-block;
      border-radius: 4px;
      padding: 16px 30px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      font-weight: 500;
      position: relative;
      letter-spacing: -0.5px;

      &:hover {
        background: #444;
        color: #fff;
      }
    }
  }

  .nav-head h1 {
    margin: 0;
    font-family: "Karla", "Open Sans";
    font-weight: bold;
    font-size: 26px;
    text-transform: none;
    color: #333;
    text-align: left;
    padding: 0;
  }

  .nav-body {
    overflow: auto;
    background: #fdfdfd;
  }

  .nav-foot {
    padding: 20px;
    overflow: auto;
    display: table;
    width: 100%;
    min-height: 80px;
    position: absolute;
    bottom: 0;

    .price {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .price .currency {
    font-size: 10px;
    position: relative;
    top: -4px;
  }

  .nav-activity,
  .nav-foot .foot-price .accent {
  }

  .tablets {
    width: 100%;
    overflow: auto;
  }

  .tablet {
    width: 50%;
    float: left;
    padding: 10px 5px 30px;
  }

  .divided > div {
    border: solid rgba(0, 0, 0, 0.1);
    border-width: 1px 0 0 0;

    &:nth-child(even) {
    }

    &:nth-child(odd) {
      border-width: 1px 1px 0 0;
    }

    &:first-of-type,
    &:nth-child(2) {
      border-top: 0;
    }
  }

  .tablet-img {
    text-align: center;

    i {
      padding: 20px;
      font-size: 25px;
    }
  }

  .tablet-text {
    text-align: center;

    h3 {
      font-size: 14px;
      margin: 0 0 5px;
      padding: 0;
      text-transform: none;
    }

    h4 {
      margin: 0;
      padding: 0;
    }
  }

  .pills {
  }

  .pill {
    width: 50%;
    float: left;
    padding: 10px 20px;

    h3,
    h4 {
      margin: 0;
    }

    h3 {
      padding: 0 0 5px;
      font-family: "Karla";
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      font-size: 13px;
    }

    h4 {
      padding: 0;
    }
  }
  /* build by */
  #super-actions,
  .app-overlay-bottom {
  }

  .intro {
    /* width: 600px; */
    margin: 0 auto 50px;
    text-align: center;
    color: #fff;
    position: relative;
  }

  .intro-arrow {
    margin: 0 auto 65px;

    &:before {
      content: "";
      height: 50px;
      width: 50px;
      border: 1px solid rgba(255, 255, 255, 0.19);
      position: absolute;
      bottom: -20px;
      right: auto;
      left: auto;
      border-radius: 50%;
      margin-left: -25px;
      z-index: 0;
    }

    &:after {
      content: "\2193";
      font-size: 60px;
      margin: 20px 0 0;
      font-weight: 100;
      display: block;
      color: rgba(255, 255, 255, 0.7);
      text-shadow: 0 11px 50px rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 1;
    }
  }

  .intro {
    h1,
    h2 {
      color: #fff;
    }

    h1 {
      font-size: 22px;
      font-weight: 100;
      line-height: 40px;
      margin: 0;
      padding: 0;
      text-transform: none;
      text-shadow: 0 10px 10px rgba(0, 0, 0, 0.11);

      b {
        font-weight: 500;
        text-shadow: 0 10px 10px rgba(0, 0, 0, 0.02);
      }
    }

    h2 {
      font-size: 50px;
      text-transform: none;
      margin: 20px 0 10px;
      text-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      font-weight: 600;
      padding: 0;
    }
  }
  /* inline panes */
  .inline-panes {
    .app-pane {
      position: relative;
      margin: 20px;
      text-align: left;
      box-shadow: 0 20px 15px rgba(0, 0, 0, 0.2);

      .go:after,
      .redo:after {
        transition: all 0.3s ease;
        will-change: right;
        right: 0;
      }

      &:hover .go:after,
      &:hover .redo:after {
        right: -6px;
      }
    }

    .pane-head {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      background: rgba(255, 255, 255, 0.4);
      padding: 20px 30px;
      display: table;
    }

    .head-image {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
    }

    .head-content {
      display: table-cell;
      vertical-align: middle;
      padding-left: 10px;

      h2 {
        font-size: 19px;
        margin: 2px 0 0;
        padding: 0;
        line-height: 1;
      }
    }

    .pane-content {
      padding: 20px 30px;
    }

    .head-image i {
      font-size: 25px;
    }
  }
  /*building */
  .panes-building .app-pane {
    &:after,
    &:before {
      content: "";
    }
  }

  .pane-stage {
    background: rgba(255, 255, 255, 0.85);

    .app-pane-inner {
      display: table;
    }

    .pane-image {
      text-align: center;
      font-size: 40px;
      padding: 25px 0 0;

      i:before {
        font-size: 40px;
        margin: 0 0 0 -1px;
      }
    }
  }

  .app-pane.pane-stage {
    text-align: center;
    margin: 20px;
    background: #fff;
  }

  .part-selectors {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .select2-container--default,
    select {
      flex: 1 0 16%;
      display: flex;
      margin: 0 20px;
      box-shadow: 0 20px 15px rgba(0, 0, 0, 0.2);
      border-radius: 7px;
    }
  }

  .part-selector {
    flex: 1 0 16%;
    display: flex;
    margin: 0 20px;
    box-shadow: 0 20px 15px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
  }

  .nav-part .select2-container--default {
    margin: 0;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #666;
    text-shadow: none;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    height: 50px;
  }

  .app-pane.pane-stage {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      margin-top: 0;
    }
  }

  .pane-stage {
    .pane-foot {
      background: #fbfbfbfb;
      padding: 15px 20px 20px;
      position: absolute;
      bottom: 0;
      height: 51px;
      left: 0;
      right: 0;
    }

    h2 {
      font-size: 17px;
      margin: 10px 0 4px;
      padding: 0;
      line-height: 1;
    }

    .pane-content {
      padding: 10px 0 66px;
      background: #ffffff;
      background: linear-gradient(to bottom, #fff, rgba(0, 0, 0, 0.06));
    }
  }

  .valign-c {
    display: table;
    height: 100%;
    /* */
    width: 100%;
  }

  .valign-c-item {
    display: table-cell;
    vertical-align: middle;
  }

  .go,
  .redo {
    &:after {
      content: "\27F6";
      position: relative;
      top: -1px;
      padding: 0 0 0 6px;
    }

    &.back:after {
      content: "\27F5";
      padding: 0 10px 0 0;
      float: left;
      left: 0;
    }
  }

  .redo {
    &:after {
      content: "\f2f9";
      font-family: "Line Awesome Free";
      float: left;
      padding: 0 10px 0 0;
      margin-left: -7px;
      top: 0;
    }
  }

  .status-icon {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.1);
  }

  .pane-stage .status-icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .status-icon {
    .danger,
    .success {
      position: absolute;
      right: 0;
      transition: 0.15s ease all;
      will-change: opacity;
      z-index: 1;
    }

    .success {
      color: #72bb72;
      opacity: 1;
    }
  }

  .inline-panes .app-pane:hover {
    .danger:after,
    .status-icon .danger {
      opacity: 1;
    }
  }

  .status-icon .danger {
    opacity: 0;
  }

  .inline-panes {
    .app-pane:hover {
      .status-icon .success {
        opacity: 0;
      }

      .danger:after {
        content: "";
        position: absolute;
        display: block;
        width: 300%;
        height: 300%;
        border-radius: 45px;
        background-color: #d9534f42;
        -webkit-animation: pulse-ring 0.7s cubic-bezier(0.215, 0.61, 0.355, 1)
          forwards;
        animation: pulse-ring 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
        top: -12px;
        right: -12px;
        opacity: 1;
      }
    }

    .status-icon .danger:hover {
      transform: scale(1.25);
    }

    .app-pane:hover .status-icon:hover .danger:after {
      opacity: 0;
    }
  }
  @-webkit-keyframes pulse-ring {
    0% {
      -webkit-transform: scale(0.33);
      transform: scale(0.33);
    }

    100%,
    80% {
      opacity: 0;
    }
  }
  @keyframes pulse-ring {
    0% {
      -webkit-transform: scale(0.33);
      transform: scale(0.33);
    }

    100%,
    80% {
      opacity: 0;
    }
  }

  .danger {
    color: #d9534f;
  }

  .warning {
    color: #de820d;
  }

  .intro.continue {
    margin: 40px auto 80px;

    h1 {
      font-size: 22px;
    }

    &:after,
    &:before {
      display: none;
    }
  }

  .floating-head {
    width: 100%;
    padding: 40px;
    text-align: center;

    a {
      border: 1px solid rgba(255, 255, 255, 0.6);
      padding: 7px 15px;
      border-radius: 5px;
      color: #fff;
      margin: 0 10px;
    }
  }
  /* BEGIN2 ONLY: uncomment for begin1 */
  .part-selector,
  .part-selectors select {
    font-family: Karla;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 10px;
  }

  .part-selectors {
    .select2-container--default {
      font-family: Karla;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 10px;
    }

    select {
      flex: 1 0 16%;
      display: flex;
      margin: 0 20px;
      box-shadow: 0 20px 15px rgba(0, 0, 0, 0.2);
      border-radius: 7px;
      background-color: rgba(255, 255, 255, 0.4);
      border: 1px solid rgba(255, 255, 255, 0.06);
      color: #fff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
      padding: 9px;
      height: 48px;
    }
  }

  .part-selector {
    flex: 1 0 16%;
    display: flex;
    margin: 0 20px;
    box-shadow: 0 20px 15px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.06);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    padding: 9px;
    height: 48px;
  }

  .part-selectors {
    .select2-container--default {
      flex: 1 0 16%;
      display: flex;
      margin: 0 20px;
      box-shadow: 0 20px 15px rgba(0, 0, 0, 0.2);
      border-radius: 7px;
      background-color: rgba(255, 255, 255, 0.4);
      border: 1px solid rgba(255, 255, 255, 0.06);
      color: #fff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
      padding: 9px;
      height: 48px;
    }

    .select2-selection__placeholder {
      color: #fff !important;
    }
  }

  .nav-part .select2-selection__arrow {
    top: calc(50% - 23px) !important;
    right: 8px !important;
  }
  /* needs review begin */
  #product-single {
  }

  .single-hero {
    background: #eee;
    min-height: 400px;
  }

  .hero-left {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    width: calc(800px + 80px + 80px);
    margin: 0 auto;
  }

  .hero-right {
  }

  .single-content {
    background: #fff;
    min-height: 800px;
    padding-top: 210px;
    padding-bottom: 60px;
  }

  .hero-left {
    .img {
      width: 100%;
      height: 420px;
      box-shadow:
        0 10px 20px rgba(0, 0, 0, 0.1),
        0 7px 8px rgba(0, 0, 0, 0.1);
      background: #fff;
      position: relative;
    }

    .img-inner {
      width: 800px;
      height: 420px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .hero-title {
    position: relative;
    padding: 10px 0 0;
    text-align: center;

    h1,
    h2 {
      text-align: center;
    }

    h1 {
      font-weight: bold;
      color: #333;
      font-size: 30px;
      letter-spacing: -1px;
      text-transform: none;
      margin: 35px 0 9px;
      padding: 0;
      line-height: 1;
    }

    h2 {
      font-size: 16px;
      margin: 0;
      padding: 0;
      color: #555;
      line-height: 1;
      font-family: Playfair DIsplay;
      font-style: italic;
      text-transform: none;
      font-weight: 500;
    }
  }

  .hero-arrows {
    position: absolute;
    bottom: 0;
    right: 0;
    display: table;

    > div {
      background: #333;
      height: 80px;
      width: 80px;
      text-align: center;
      color: #fff;
      font-size: 0;
      bottom: 3px;
      z-index: 1;
      border: 0;
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
  }
  /* style as if for <button> */
  .slick-arrow {
    background: #333;
    height: 80px;
    width: 80px;
    text-align: center;
    color: #fff;
    font-size: 0;
    bottom: 3px;
    z-index: 1;
    border: 0;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    &:before {
      font-size: 20px;
    }
  }

  .slick-prev {
    right: 80px;
  }

  .hero-arrows > div:first-child:before,
  .slick-prev:before {
    content: "\27F5";
    position: relative;
    top: -2px;
  }

  .hero-arrows > div:last-child:before,
  .slick-next:before {
    content: "\27F6";
    position: relative;
    top: -2px;
  }

  .hero-lefter,
  .hero-right {
    width: 80px;
  }

  .hero-lefter {
    padding: 40px 40px 0 0;
  }

  .hero-right {
    padding: 40px 0 0 40px;
  }

  .hero-content,
  .hero-lefter,
  .hero-right {
    float: left;
  }

  .share-buttons {
    visibility: hidden;

    > * {
      visibility: visible;
      transition:
        opacity 150ms linear 100ms,
        transform 150ms ease-in-out 100ms;
      will-change: opacity, transform;
    }

    &:hover > * {
      opacity: 0.6;
      transform: scale(0.9);
      color: #777 !important;
    }

    > *:hover {
      opacity: 1;
      transition-delay: 0ms, 0;
      transform: scale(1);
    }
  }

  .hero-lefter button,
  .hero-right button,
  .social-share {
    display: block;
    background: #fff !important;
    width: 40px;
    height: 40px;
    margin: 11px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    color: #bbb !important;
    transition: all ease 0.3s;
  }

  .share-buttons {
    button {
      display: block;
      background: #fff !important;
      width: 40px;
      height: 40px;
      margin: 11px 0;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
      color: #bbb !important;
      transition: all ease 0.3s;

      &:hover {
        color: #fff !important;
        background: #333 !important;
      }
    }

    .fb:hover {
      background: #4267b2 !important;
    }

    .tw:hover {
      background: #1da1f2 !important;
    }

    .pin:hover {
      background: #e60023 !important;
    }
  }

  .single-content .row-7-3 {
    max-width: 999px;
  }

  .row-3-3-3,
  .row-5-5,
  .row-6-4,
  .row-7-3 {
    display: flex;
    margin: 0 auto;
  }

  .row-3-3-3 .col,
  .row-5-5 .col,
  .row-6-4 .col,
  .row-7-3 .col {
    display: flex;
    flex-direction: column;
  }

  .row-7-3 .col {
    &:first-child {
      width: 70%;
      padding: 0 5% 0 0;
    }

    &:last-child {
      width: 30%;
    }
  }

  .row-6-4 .col {
    &:first-child {
      width: 60%;
      padding: 0 5% 0 0;
    }

    &:last-child {
      width: 40%;
    }
  }

  .row-3-3-3 .col {
    width: 33.3%;
    padding: 0 30px;
  }

  .row-5-5 .col {
    width: 50%;
    padding: 0 30px;
  }

  .lead {
    margin: 0 0 30px;
    font-size: 20px;
    font-style: italic;
    font-family: "Playfair Display";
    line-height: 40px;
    white-space: pre-line;
  }

  .lead-alt {
    font-family: "Karla", "Open Sans";
    letter-spacing: -1px;
  }

  .lead-strong {
    font-weight: 600;
    letter-spacing: 2px;
  }

  .cta {
    font-weight: bold;
    z-index: 1;
    width: 296px;
    text-align: center;
    position: absolute;
    right: 0;
    border-radius: 3px;
    padding: 13px 0;
    border: 1px solid #72bb72;
    background: transparent;
    color: #72bb72;
    top: 12px;
  }

  .tab-section {
    max-width: 992px;
    margin: 0 auto;
    position: relative;
  }

  .tab-header {
    border-top: 1px solid #eee;
    padding-bottom: 30px;
    margin: 20px 0 40px;

    .tab-regular {
      margin: 0 40px;
      padding: 30px 0;
      min-height: 0;
      line-height: 1;
      font-weight: bold;
      position: relative;
      font-size: 17px;
      letter-spacing: -1px;
      color: #888;
      text-transform: none;
      min-width: 0;
      overflow: visible;

      &:first-child {
        margin-left: 0;
        color: #333;
      }

      &.Mui-selected:after {
        content: "";
        width: 100%;
        position: absolute;
        background: #333;
        height: 3px;
        left: 0;
        top: -1px;
      }
    }
  }

  .single-content {
    h2 {
      text-transform: none;
      font-size: 22px;
    }

    p:not(.lead) {
      padding: 0 0 25px;
      line-height: 1.9;
    }

    h3 {
      text-transform: none;
    }
  }

  table {
    margin: 10px 0 40px;
    width: 100% !important;

    thead th {
      padding: 25px 0;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -1px;
      border-top: 1px solid #eee;
      color: #aaa;

      &:first-child {
        padding-left: 10px;
      }
    }

    tbody {
      td:first-child {
        padding-left: 10px;
      }

      tr:nth-child(odd) {
        background: #f3f3f3;
      }

      td {
        padding: 20px 0;
      }
    }
  }

  .single-image {
    height: 180px;
    width: 100%;
    margin: 0 0 30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    img {
      min-height: 0;
      object-fit: cover;
      height: 100%;
      width: auto;
    }
  }

  .intro-left {
    width: 100%;
    text-align: left;

    h1,
    h2 {
      text-align: left;
    }
  }

  .intro p {
    max-width: 480px;
  }

  .panes-building {
    /* margin-bottom: 40px; */
  }

  .part-section {
    position: relative;

    .part-selectors {
      width: 500px;
      margin: 0 auto;

      select {
        margin: 0;
      }
    }
  }

  .intro-inline {
    margin-bottom: 20px;
  }

  .pane-top-right {
  }

  #edit-actions .form-submit.go:after,
  .pane-actions .cta-1.go:after {
    padding: 0;
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .pane-actions .cta-1.go {
    text-align: left;
    padding: 0 120px 0 30px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.25);
    border-radius: 5px;
  }

  .pane-bottom-left {
    left: 30px;
  }

  .partnumberv2 .pane-bottom-left {
    bottom: 45px;
  }

  .app-overlay-bottom-scroll {
    min-height: calc(100vh - 460px);
    padding: 40px 0 100px;
    margin: 400px 0 0;
    background: #fff;
    /* z-index: 99999; */
    z-index: 1;
    position: relative;
  }

  .the-form {
    margin: 40px -15px 0;
    overflow: auto;

    .form-field {
      float: left;
      width: 50%;
      padding: 0 15px;
      margin: 18px 0;

      &.form-field-checkbox {
        label {
          display: inline-block;
        }

        input {
          float: left;
          margin-right: 10px;
        }
      }
      // &.field_create_revit {
      //   label {
      //     .revitIcon {
      //       // position: absolute;
      //       // left: 0;
      //       // top: -9px;
      //     }
      //   }
      // }
      &.field_spec_notes {
        float: left;
        padding: 0 15px;
        // width: 66.6%;
        width: 100%;
      }
    }

    #fieldsOptions {
      float: left;
      width: 100%;
      padding: 15px 0 0;
      display: flex;

      .form-field {
        margin: 0 0 10px;
        width: 100%;

        label {
          font-size: 11px;
        }
      }
    }

    input[type="text"] {
      height: 48px;
      width: 100%;
      padding: 4px 12px;
      border: 1px solid #c7c7c7;
      background-color: #fff;
      color: rgba(51, 51, 51, 0.5);
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      -ms-box-shadow: none;
      box-shadow: none;

      &:focus {
        border: 1px solid #000;
      }
    }

    textarea:focus {
      border: 1px solid #000;
    }

    label {
      color: #333;
      margin: 0 0 8px;
      font-size: 12px;
    }

    input[type="text"],
    select {
      border: 0;
      border-radius: 3px;
      background: #f4f4f4;
    }

    textarea {
      border: 0;
      border-radius: 3px;
      background: #f4f4f4;
      height: 95px;
      min-height: 75px;
    }

    .form-field.submit_button {
      width: 50%;
      // float: none;
      // padding-top: 20px;
      margin-top: 20px;
      // overflow: auto;
      // clear: both;
      button {
        border-radius: 3px;
        width: 100%;
        height: auto;
        border: 0;
        background: #444;
        color: #fff;
        font-size: 20px !important;
        text-transform: none;
        letter-spacing: 0;
        padding: 18px 30px 37px;
        text-align: left;
        position: relative;
      }
    }
  }

  .app-overlay-bottom-scroll {
    .inline-panes {
      .app-pane {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.04);
        border: 1px solid #eee;
        max-width: 250px;
      }

      .pane-foot {
        background: transparent;
        border-top: 1px solid #eee;
      }
    }

    .cntnt .intro {
      h1,
      h2,
      p {
        color: #333;
      }

      h3 {
        padding: 0;
        margin: 0;
        text-transform: none;
        font-size: 26px;
      }
    }
  }

  .information p {
    line-height: 1.9;
    /* New */
    margin-bottom: 15px;
  }

  .three-info-overlay {
    margin-top: -220px;
    padding-bottom: 100px;
    height: 215px;

    h1,
    h2 {
      color: #fff;
    }

    .intro h1 {
      margin: 0;
    }

    .currency,
    .pricing {
      display: inline;
    }

    .pricing {
      font-weight: bold;
      font-size: 27px;
      padding: 0 0 0 5px;
      color: #fff;
    }

    .currency {
      font-size: 13px;
      font-weight: normal;
      position: relative;
      top: -9px;
      padding-right: 2px;
    }
  }

  .nav-body {
    .content {
      padding: 20px;
    }

    .content-inner {
      padding-bottom: 40px;
    }
  }

  .color-swatch {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .swatch {
    display: flex;
    flex-direction: column;
    width: 20%;
    text-align: center;
  }

  .swatch-img {
    width: 65px;
    overflow: hidden;
    height: 65px;
    margin: 0 auto;
    border-radius: 50%;
    border: 4px solid #eee;
    box-shadow: 0 0 0 3px #fff;
  }

  .swatch-legend {
    font-size: 10px;
    margin: 8px 0 10px;
  }

  .swatch i {
    font-size: 9px;
    vertical-align: text-top;
    color: #fbb32e;
    padding: 0 4px 0 0;
  }

  .swatch-code {
    font-size: 13px;
  }

  .swatch-name {
    display: block;
    padding: 5px 0 0;
    font-size: 12px;
    margin: 0 0 10px;
  }

  .nav-body h3 {
    text-transform: none;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 20px;
    padding: 0;
  }

  .section {
    margin-bottom: 20px;
  }

  .single-section {
    background: #fff;
    border-top: 1px solid #eee;
    min-height: 400px;
    padding: 30px 0 70px;

    h2 {
      text-transform: none;
      letter-spacing: -1px;
      font-size: 26px;
      text-align: center;
      padding: 0;
      margin: 0 0 50px;
    }
  }

  .related-item {
    .related-image {
      width: 100%;
      height: 200px;
      background: #fff;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      margin: 0 0 30px;
    }

    h3 {
      font-size: 19px;
      text-transform: none;
      letter-spacing: -1px;
      margin: 0 0 8px;
      padding: 0;
    }

    h4 {
      font-family: Playfair Display;
      text-transform: none;
      font-style: italic;
      font-size: 13px;
      color: #777;
      padding: 0;
      margin: 0;
    }
  }

  .single-category {
    padding-bottom: 50px;
    min-height: 0;
    text-align: center;

    h2 {
      margin-bottom: 25px;
    }

    p.lead {
      max-width: 680px;
      font-style: normal;
      text-align: center;
      margin: 0 auto;
      font-size: 17px;
      font-family: "Playfair Display";
      line-height: 32px;
      white-space: pre-line;
    }
  }

  .cta-lead {
    color: #333;
    font-family: "Playfair Display";
    font-style: italic;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    margin: 20px 0 0;
    display: inline-block;
    border-radius: 3px;
  }

  #product-single .container {
    min-width: 1240px;
  }
  /* FOR index.html / color.html ONLY: */
  .pane-actions {
  }
  /* END*/
  .part-section {
    .parts {
      margin: 40px auto;
      position: relative;
      max-width: 800px;
      text-align: center;
    }

    .part {
      background: rgba(255, 255, 255, 0.9);
      border-radius: 5px;
      display: inline-block;
      padding: 13px 10px;
      margin: 0 5px 10px;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
      border: 1px solid #fcfcfc;
      font-family: Karla;
      white-space: nowrap;

      i {
        font-size: 11px;
        position: relative;
        top: -2px;
        padding: 0 0 0 5px;
      }
    }
  }

  #toggleThreeContainer {
    position: absolute;
    top: 25px;
    left: 25px;
    /* @2020053115.51 */
    z-index: 99;
  }

  a.toggle-href {
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    float: left;
    margin: 0 15px 0 0;
    transition: all ease 0.3s;
    cursor: pointer;
    height: 30px;
    background: #333;
    display: block;
    border-radius: 100px;
    font-weight: 600;
    margin-bottom: 3px;
    text-transform: uppercase;
    font-family: Karla;
    font-size: 9px;
    color: #fff;
    letter-spacing: 0;
    padding: 11px 13px 10px 34px;
    white-space: nowrap;

    i:not(.las) {
      position: absolute;
      top: 4px;
      left: 5px;
      width: 22px;
      height: 22px;
      background: #fff;
      border-radius: 90px;
      color: #333;
      padding: 6px;
      font-size: 11px;
    }
  }
  // .mode-light {
  //
  // }
  a.toggle-href {
    span {
      position: relative;
    }

    i.las {
      display: none;
    }

    &.selected {
      background: #72bb72;
      color: #fff;

      i:not(.las) {
        left: auto;
        right: 5px;
        background: #fff;
      }

      span {
        left: -20px;
      }
    }
  }

  .num-steps {
    counter-reset: section;
    margin: 40px 0 0;
    max-width: 600px;

    > div {
      font-size: 15px;
      max-width: 400px;
      line-height: 1.2;
      color: #999;
      margin: 0 0 50px;
      padding: 0 0 0 64px;
      position: relative;
      font-family: Karla;
      font-weight: 300;
      &::before {
        counter-increment: section;
        content: counters(section, ".") ". ";
        display: inline-block;
        text-align: center;
        line-height: 0;
        font-size: 67px;
        font-weight: bold;
        font-style: normal;
        position: absolute;
        left: 0;
        color: #ddd;
        font-family: Playfair Display;
      }
    }
  }

  .button--add-to-cart {
    width: calc(100% - 55px);
    color: #fff;
    border-color: #fff;
    border-left: 0;
    left: 55px;
    position: relative;

    &:active,
    &:focus,
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .price-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0 0 25px;
  }

  .price-desc {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    font-family: Karla;
    position: relative;
    top: -4px;
  }

  .price-value {
    font-size: 30px;
  }

  #app_pbQuantity {
    // position: absolute;
    // top: 0;
    // z-index: 1;
    // bottom: 0;
    // left: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  #app_pbQuantity input {
    width: 85px;
    height: 100%;
    line-height: 1.65;
    float: left;
    display: block;
    margin: 0;
    border: 1px solid #fff;
    border-radius: 0;
    padding: 0 4px 4px 10px;
    color: #72bb72;
    color: #aaa;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    text-indent: -26px;
    width: 80px;

    &:focus {
      outline: 0;
    }
  }

  .quantity-nav {
    float: left;
    position: relative;
    height: 100%;
  }

  .quantity-button {
    position: relative;
    cursor: pointer;
    border-left: 1px solid #72bb72;
    border-left: 1px solid #aaa;
    width: 20px;
    text-align: center;
    color: #72bb72;
    color: #aaa;
    font-size: 13px;
    font-family: "Trebuchet MS", Helvetica, sans-serif !important;
    line-height: 38px;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    border-left: none;

    &.quantity-up {
      position: absolute;
      height: 50%;
      top: 0;
      // border-bottom: 1px solid #72bb72;
      // border-bottom: 1px solid #AAA;
      padding-top: 2px;
    }

    &.quantity-down {
      position: absolute;
      bottom: 0;
      height: 50%;
      line-height: 28px;
    }
  }

  .add-cart-wrap .form-actions {
    margin: 0 0 43px;
    float: left;
    width: 100%;
    position: relative;
  }

  .cta-atc.go:after,
  .cta-lg.go:after {
    content: "\27F6";
    position: relative;
    top: -1px;
    padding: 0 0 0 6px;
    right: 30px;
    position: absolute;
    top: 50%;
    line-height: 0;
    font-size: 30px;
  }

  .status-icon-tag.danger {
    background: #d9534f;
    color: #fff;
    border-radius: 50px;
    display: flex;
    padding: 3px 4px;
  }

  .status-tag {
    padding: 0 5px;
    display: inline;
    vertical-align: middle;
  }

  .nav-part {
    padding: 25px 20px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  .the-form .form-field.submit_button button {
    &:before {
      content: "Save now";
      font-family: "Karla";
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 9px;
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      position: absolute;
      bottom: 18px;
      left: 30px;
    }

    &.go:after {
      right: 22px;
      position: absolute;
      top: 24px;
    }
  }

  .container-fluid {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .container-section:nth-child(odd) {
    background: #f5f5f5;
  }

  .container-section-reverse {
    &:nth-child(odd) {
      background: #fff;
    }

    &:nth-child(even) {
      background: #f5f5f5;
    }
  }

  .container-section {
    padding: 50px 0;
  }
  /* should be for all, .summary-ing for now */
  .app-overlay-bottom-scroll {
    padding: 30px 0 100px;

    .three-info-overlay + .cntnt {
      margin-top: -45px;
    }

    .app-pane.pane-stage,
    .panes-building {
      margin-bottom: 0;
    }
  }

  .cta-accent {
    font-family: Karla;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 11px;
    margin: 8px 0 0;
    color: rgba(0, 0, 0, 0.2);
    opacity: 1;
    transition: 0.2s all ease;
  }

  .cta-lg {
    padding: 40px;
    text-align: center;
    margin: 0;
    position: relative;
    background: #eee;
    color: rgba(0, 0, 0, 0.2);

    &.stacked {
      display: block;
    }

    .cta-title {
      font-size: 30px;
      font-weight: bold;
      opacity: 1;
      transition: 0.2s all ease;
    }

    &.stacked {
      padding: 40px 40px 40px 130px;
      text-align: left;
      border-top: 1px solid #eee;
      color: rgba(0, 0, 0, 0.5);
      background: transparent;

      .cta-icon {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        color: #ddd;
        line-height: 0;
        width: 87px;
        text-align: center;
        font-size: 30px;

        i {
          top: 50%;
          position: relative;
          line-height: 0;
        }
      }
    }
  }

  .app-header {
    height: 120px;
    top: 0;
    position: absolute;
    width: 100%;
    overflow: hidden;
    z-index: 99999;
  }

  .page-render {
    position: relative;
    // top: 120px;
    // padding-bottom: 120px;
    /* testing @500px for bugs; change to 50px on resp completion */
  }

  .app-header #toggleThreeContainer {
    top: 15px;
  }

  .header-nav {
    float: right;
    margin: 15px 25px;
    height: 40px;
  }

  .header-nav-action {
    float: left;
    height: 40px;
    padding: 12px 30px;
    border-radius: 4px;
    margin-left: 10px;
    font-family: Karla;
    letter-spacing: -1px;
    font-weight: 600;
  }

  .header-nav {
    .breadcrumb {
      border: 1px solid rgba(255, 255, 255, 0.3);
      color: rgba(255, 255, 255, 0.93);
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    }

    .save {
      background: #72bb72;
      color: #fff;
      font-weight: 600;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);

      &.back {
        background: #333;
      }
    }

    .cart {
      position: relative;
      margin-left: 55px;
      padding: 0 18px;
      color: #fff;
      background: #444;
      cursor: pointer;

      .count {
        font-size: 10px;
        background: rgba(255, 255, 255, 0.2);
        height: 17px;
        border-radius: 50px;
        padding: 0 8px 0 7px;
        line-height: 18px;
        font-family: "Arial";
      }

      &:before {
        content: "";
        height: 100%;
        width: 1px;
        background: rgba(255, 255, 255, 0.25);
        position: absolute;
        left: -30px;
      }
    }

    .cost {
      font-weight: 300;
      background: #f4f4f4;
      border: 1px solid transparent;

      .text {
        line-height: 0;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
        font-size: 9px;
        vertical-align: middle;
        color: rgba(0, 0, 0, 0.4);
      }
    }

    .accent {
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1px;
      font-size: 9px;
      vertical-align: middle;
      color: rgba(0, 0, 0, 0.4);
    }

    .breadcrumb .accent {
      color: rgba(255, 255, 255, 0.7);
    }

    .value {
      font-weight: 600;
      padding-left: 5px;
    }
  }

  // .mode-light {}
  ///
  .pane-image-configured {
    padding: 25px 0 0;
    text-align: center;

    img {
      max-width: 95px;
      max-height: 95px;
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .select2-results__option {
    .code {
      font-weight: bold;
      vertical-align: middle;
    }

    .name {
      margin: 0 0 0 17px;
      font-size: 11px;
      color: rgba(255, 255, 255, 0.6);
      vertical-align: middle;
      font-family: Karla;
      font-weight: 300;
      float: right;
      max-width: 130px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .category {
      font-family: Karla;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 11px;
      margin: 0 0 0 15px;
      color: rgba(255, 255, 255, 0.4);
      vertical-align: middle;
      float: right;
    }
  }

  .bkPb-MuiTooltip-tooltip {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
    color: #444 !important;
    font-size: 14px !important;
    img {
      height: 40px;
      width: auto;
      margin-top: 5px;
    }
  }

  .tooltip--block,
  .tooltip-slim-line {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.07);
    font-size: 12px;
    padding: 5px 13px;
    border-radius: 3px;
    position: relative;
  }

  .tooltip-slim-line {
    background: rgba(255, 255, 255, 0.8);
  }

  .tooltip--block {
    background: #fff;
    opacity: 0.9;
    width: 120px;
  }

  .bkPb-MuiTooltip-tooltip .tooltip-title {
    color: #444;
    font-size: 14px;
  }

  .tooltip--block .tooltip-title {
  }

  .ccn-tabs {
    text-align: center;
    margin: 0 auto 40px;
    max-width: 500px;
    padding: 11px;
  }

  .bkPb-MuiButtonBase-root.ccn-tab {
    color: #fff;
    font-family: "Karla";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    padding: 11px 15px;
    min-height: 48px;
  }

  // .mode-dark {
  //   .bkPb-MuiButtonBase-root.ccn-tab {
  //     border-radius: 3px;
  //     border: 1px solid transparent;
  //   }

  //   .ccn-tab.Mui-selected {
  //     color: #fff;
  //     font-family: Karla;
  //     font-weight: bold;
  //     text-transform: uppercase;
  //     font-size: 11px;
  //     letter-spacing: 1px;
  //     text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  //     padding: 10px 15px;
  //     background: rgba(255, 255, 255, 0.1);
  //     border: 1px solid rgba(255, 255, 255, 0.1);
  //     margin: 0 15px;
  //     border-radius: 3px;
  //     min-height: 0;
  //   }
  // }

  .App .bkPb-MuiSnackbarContent-root {
    position: relative;
    top: 46px !important;
  }

  // .mode-light {}

  .part-selector {
    text-shadow: none;
    width: 230px;
    background: #fff;
    font-family: "Karla", "Open Sans";
  }

  // .mode-light {}

  .pane-stage {
    .pane-content,
    .pane-foot {
      background: transparent;
    }

    h2 {
      text-transform: none;
      letter-spacing: -1px;
      font-size: 18px;
      color: #111;
    }

    .pane-image {
      padding: 45px 0 0;
    }
  }

  // .mode-light {}

  .pane-stage h2 {
    font-weight: 300;
  }

  // .inline-panes {
  //   .slick-track {
  //     display: flex;
  //     align-items: stretch;
  //     justify-content: center;

  //     > .slick-slide {
  //       float: none;
  //       height: auto;
  //       min-height: auto;

  //       > div {
  //         height: 100%;

  //         > div {
  //           height: 100%;

  //           .app-pane {
  //             height: calc(100% - 30px);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // .inline-panes.panes-building {
  //   // visibility: hidden;
  //   justify-content: center;

  //   .slick-dots {
  //     position: absolute;
  //     left: 0;
  //     right: 0;
  //     display: flex!important;
  //     justify-content: center;

  //     li.slick-active button:before {
  //       color: #fff;
  //     }
  //   }

  //   .slick-arrow {
  //     background: none;
  //     top: 0;
  //     bottom: 0;
  //     margin: auto 0;
  //     height: 100%;
  //     text-align: center;

  //     &:before {
  //       position: absolute;
  //       bottom: -20px;
  //       left: 0;
  //       right: 0;
  //       top: auto;
  //     }

  //     &.slick-prev {
  //       left: 0;
  //       right: auto;
  //     }

  //     &.slick-next {
  //       right: 0;
  //       left: auto;
  //     }
  //   }
  //   // .slick-track {
  //   // > * {
  //   //   visibility: visible;
  //   //   transition: opacity 150ms linear 100ms, transform 150ms ease-in-out 100ms;
  //   //   will-change: opacity, transform;
  //   // }
  //   //
  //   // &:hover > * {
  //   //   opacity: 0.6;
  //   //   transform: scale(0.94);
  //   // }
  //   //
  //   // > *:hover {
  //   //   opacity: 1;
  //   //   transform: scale(1);
  //   //   transition-delay: 0ms, 0;
  //   // }
  //   // }
  //   .slick-list {
  //     overflow: visible;
  //   }
  // }

  // .catalog #app-nav-container,
  // .not-building#app-nav-container {
  //   /* width: calc(100% - 50px);
  // height: calc(100vh - 95px); */
  // }

  .catalog #app-nav,
  .not-building #app-nav {
    width: 100%;
  }

  #app-nav-container {
    margin: 0 25px;
  }

  .catalog .pane-actions,
  .pane-actions.not-building {
    bottom: -80px;
  }

  // .pane-stage {
  //   &.stage--fixture .pane-image-configured img {}

  //   &.stage--lens .pane-image-configured img {
  //     max-height: 47px;
  //   }

  //   &.stage--lamping .pane-image-configured img {
  //     max-height: 55px;
  //   }

  //   &.stage--mounting .pane-image-configured img {}

  //   .pane-name {
  //     display: block;
  //     line-height: 1.4;
  //     margin: 8px 25px 0;
  //   }

  //   .pane-code {
  //     color: #aaa;
  //   }
  // }

  .not-building .nav-body #ccn-tabs {
    width: 100%;
  }

  .tab-item {
    float: left;
  }

  .bkPb-MuiDrawer-paperAnchorRight {
    width: 100%;
    background: #fdfdfd !important;
  }

  .category-slide {
    padding: 20px;
  }

  .category-slide-img {
    color: #fff;
  }

  .cta-cancel {
    color: #aaa;
    background: #eee;
    height: 40px;
    padding: 12px 30px;
    border-radius: 4px;
    font-family: Karla;
    letter-spacing: -1px;
    font-weight: 600;
    display: inline-block;
    &::after {
      color: #aaa;
    }
    &:hover {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.06);
      background: #ccc;
      color: #fff;

      &::after {
        color: #fff;
      }
    }
  }

  .triggerDrawerSurround {
    position: relative;
  }

  .triggerDrawer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  /* .relativeDrawerContainer {background: #fdfdfd;} */
  .relativeDrawerNav {
    padding: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background: #fff;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #f6f6f6;
    /* box-shadow: 0 8px 9px -4px rgba(0,0,0,.03); */
  }

  .relativeDrawerHeading {
    padding: 20px;
    margin: 100px 0 0;

    h2 {
      text-transform: none;
      font-weight: 300;
      font-size: 27px;
    }
  }

  .relativeDrawerContent {
    padding: 0 20px 80px;

    .exp-readmore {
      margin-top: 10px;
    }
  }

  .grid-item {
    background: #fff;
  }

  .grid-item-icon > * {
    color: #222;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0;
  }

  .grid-item:hover .grid-item-icon > * {
    color: #72bb72;
  }

  .grid-item-icon .ico-lg {
    font-size: 45px;
    margin: 0 0 5px;
  }

  .grid-item-image {
    display: block;
    height: 100%;
    padding: 50px 20px 70px;
    text-align: center;
    border: 1px solid #eee;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02);
    transition: 0.2s ease all;
    will-change: box-shadow, border;
  }

  .grid-item-image:hover,
  .triggerDrawerSurround:hover .grid-item-image {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.09);
    border: 1px solid #e3e3e3;
  }

  .grid-palette-item {
    display: inline-block;
    border: 0;
    padding: 15px 10px 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    width: 100%;
    text-align: center;
    height: 100%;

    .item-image {
      border-radius: 50px;
      width: 65px;
      height: 65px;
      margin: 0 auto;
      display: block;
      overflow: hidden;
      position: relative;
      /* border: 5px solid #eee; */
      /* box-shadow: 0 0 0 4px #fff, 0 0 0 9px #f5f5f5, 0 0 0 10px #fff; */
      img {
        object-fit: cover;
        height: 100%;
        width: auto;
        min-width: 60px;
      }
      .item-image-spacer {
        &:before {
          content: "No image";
          font-size: 8px;
          font-family: "Karla";
          text-transform: uppercase;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.15);
          background: rgba(0, 0, 0, 0.04);
          border-radius: 50%;
          // height: 70px;
          // width: 70px;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          line-height: 65px;
        }
      }
    }

    .item-title {
      margin: 5px 0 0;
      font-size: 12px;
      line-height: 1;
      color: rgba(0, 0, 0, 0.5);
    }

    .item-content {
      margin: 20px 0 0;
    }

    .item-body {
    }
  }

  .grid-item-detail {
    padding: 45px 35px 60px;
    .item-title {
      font-size: 19px;
      letter-spacing: -1px;
      font-weight: 300;
    }

    .item-body {
      color: #aaa;
      font-size: 14px;
      line-height: 1.8;
    }

    .item-image img {
      max-width: 120px;
      height: 90px;
      width: auto;
    }

    .item-code {
      margin: 20px 0 0;
      color: #999;
    }

    .item-body br {
      display: none;
    }
  }

  .item-image img {
    max-height: 125px;
    height: 125px;
    width: auto;
    margin: 0 auto;
    display: block;
  }

  .item-select {
    background: #bbb;
    color: #fff;
    font-weight: 900;
    letter-spacing: -1px;
    font-size: 14px;
    padding: 8px 12px;
    display: inline-block;
    margin: 20px 0 10px;
    border-radius: 5px;
    line-height: 1.1;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0;
    will-change: background;
  }

  .grid-item:hover .item-select {
    background: #72bb72;
  }

  .item-select i {
    margin: 0 0 0 5px;
    font-size: 9px;
    vertical-align: middle;
    line-height: 1.22;
  }

  .item-btn-build {
    display: inline-block;
    font-weight: 900;
    padding: 11px 17px;
    border-radius: 3px;
    font-size: 20px;
    position: absolute;
    opacity: 0;
    bottom: 50px;
    width: 115px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-family: "Karla";
    letter-spacing: -1px;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0;
    color: #333;
  }

  .grid-item-build .item-title {
    font-weight: 100;
    font-size: 20px;
    letter-spacing: -1px;
  }

  .grid-item {
    position: relative;
  }

  .item-content {
    margin: 30px 0 0;
  }

  h3.item-title {
    margin: 0 0 10px;
  }

  .item-code {
    color: black;
  }

  .item-body {
    line-height: 1.6;
    margin: 20px 0 0;
    color: #666;

    b,
    strong {
      font-weight: 500;
      color: #aaa;
      font-size: 9px;
    }
  }

  .grid-item-small {
    padding: 20px 10px;

    .item-image img {
      max-height: 90px;
      max-width: 120px;
      height: auto;
    }

    .item-title {
      font-size: 13px;
    }

    .item-code {
      color: #bbb;
    }
  }

  .grid-item-styled {
    text-align: center;
    display: block;
    background: transparent;
    padding: 20px 0 5px;
    .item-content {
      margin: 0;
    }
  }

  .grid-item-category {
    padding: 0 0 70px;

    .item-content {
      padding: 0 40px;
    }

    .item-title {
      font-size: 27px;
      letter-spacing: -2px;
      font-weight: 100;
    }

    .item-btn {
      display: inline-block;
      background: #eee;
      padding: 13px 20px;
      border-radius: 50px;
      font-weight: 900;
      color: #aaa;
      margin: 10px 0 0;
    }

    .item-image {
      background-size: cover;
      background-position: 50% 50%;
      height: 280px;
      width: 100%;
    }

    .item-code {
      margin: 20px 0 0;
    }
  }
  /* .grid-item-category .item-image img {
  width: 100%;
  height: 100%;
  max-height: none;
} */
  .bkPb-MuiExpansionPanel-root {
    &:before {
      display: none !important;
    }
  }

  .exp-readmore {
    max-width: 700px;
    margin: 0 0 40px;
    .bkPb-MuiCollapse-root {
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0 !important;

      &:not(.bkPb-MuiCollapse-entered) {
        // visibility: visible !important;
        // height: 100% !important;
        // max-height: 54px !important;
        visibility: visible !important;
        height: 100% !important;
        position: relative !important;
      }

      &.bkPb-MuiCollapse-entered {
        max-height: 3000px !important;

        .bkPb-MuiExpansionPanelDetails-root {
          max-height: 100% !important;

          &:before {
            display: none !important;
          }
        }
      }
    }

    .bkPb-MuiExpansionPanel-root {
      display: flex;
      flex-direction: column;
      background: #fdfdfd;
    }

    .bkPb-MuiExpansionPanelSummary-root {
      padding: 0 !important;
      min-height: 0 !important;
    }

    .exp-readmore-title {
      // padding-top: 35px;
    }
  }

  .bkPb-MuiExpansionPanelSummary-root.bkPb-Mui-expanded {
    padding: 0 !important;
    min-height: 0 !important;
  }

  .exp-readmore {
    .bkPb-MuiExpansionPanelDetails-root,
    .bkPb-MuiExpansionPanelSummary-root {
      padding: 0 !important;
    }

    .bkPb-MuiExpansionPanelSummary-root {
      order: 2;
    }

    .bkPb-MuiCollapse-root {
      order: 1;
    }
  }

  .exp-readmore-content {
    font-weight: 300;
    line-height: 26px;

    * {
      line-height: 1.8;
    }

    p {
      padding: 0 0 20px !important;
    }

    ul {
      padding-bottom: 20px !important;
    }
  }

  .exp-readmore {
    .bkPb-MuiExpansionPanelSummary-content {
      margin-top: 30px;
    }

    .bkPb-MuiExpansionPanelSummary-content.bkPb-Mui-expanded {
      margin: 10px 0 0 !important;
    }

    .bkPb-MuiCollapse-container.bkPb-MuiCollapse-hidden::after {
      background: black;
      content: "";
    }
  }
  /* forced resets */
  ._da {
    transition: none !important;
  }
  // /* GRID */
  // @media (min-width: 960px) {
  //   .catalog .bkPb-MuiGrid-grid-sm-6,
  //   .not-building .bkPb-MuiGrid-grid-sm-6 {
  //     max-width: 33.3%;
  //     flex-basis: 33.3%;
  //   }
  // }
  // @media (min-width: 1280px) {
  //   .catalog .bkPb-MuiGrid-grid-sm-6,
  //   .not-building .bkPb-MuiGrid-grid-sm-6 {
  //     max-width: 25%;
  //     flex-basis: 25%;
  //   }
  // }
  // @media (min-width: 1920px) {
  //   .catalog .bkPb-MuiGrid-grid-sm-6,
  //   .not-building .bkPb-MuiGrid-grid-sm-6 {
  //     max-width: 20%;
  //     flex-basis: 20%;
  //   }
  // }
  // @media (min-width: 960px) {
  //   .nav-head {
  //     border-bottom: 1px solid #eee;
  //   }
  //
  //   .nav-part {
  //     position: absolute;
  //     top: 19px;
  //     right: 20px;
  //     width: 260px;
  //     border: 0;
  //     padding: 0;
  //   }
  //
  //   .catalog .nav-part,
  //   .not-building .nav-part {
  //     width: 500px;
  //   }
  // }
  .triggerDrawerSurround .grid-item:before {
    content: "";
  }

  .pane-build-section {
    background: transparent !important;
    position: unset !important;
    margin: 0 0 20px;
    counter-increment: section;
  }

  .pane-build-section-summary {
    padding: 20px !important;
    background: #fff !important;
    border: 1px solid #eee !important;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.023);
    &.bkPb-Mui-expanded {
      padding: 20px !important;
    }
  }

  .pane-build-section-title {
    flex-basis: 100%;
    flex-shrink: 0;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -1px;
  }

  .pane-build-section-subtitle {
    display: none;
    color: rgba(0, 0, 0, 0.54);
    font-size: 15px;
  }

  .pane-build-section-details {
    padding: 25px 0 !important;
  }

  .pane-build-section-wrapper {
    counter-reset: section;
  }

  .pane-build-section-summary {
    &:before {
      content: " " counter(section) " ";
      font-family: Karla;
      font-weight: 900;
      background: #f3f3f3;
      text-align: center;
      line-height: 46px;
      border-radius: 50px;
      display: block;
      margin: 0 20px 0 0;
      width: 46px;
      min-width: 46px;
      height: 46px;
      color: #babebf;
    }

    &.bkPb-Mui-expanded .bkPb-MuiExpansionPanelSummary-content,
    .bkPb-MuiExpansionPanelSummary-content {
      margin: 0 !important;
      align-items: center;
    }
  }

  .pane-build-section {
    &.selected .pane-build-section-summary:before {
      content: "\f00c";
      font-family: "Line Awesome Free";
      color: #fff;
      background: #72bb72;
      font-size: 20px;
    }

    .remove-selected {
      width: 46px;
      height: 46px;
      color: #fff;
      border-radius: 50px;
      background: #d9534f;
      position: absolute;
      left: 20px;
      top: 20px;
      opacity: 0;
      text-align: center;
      line-height: 47px;

      &:hover {
        opacity: 1;
      }

      &:before {
        content: "\f00d";
        font-family: "Line Awesome Free";
        font-weight: 900;
        font-size: 20px;
      }
    }
  }

  .danger .bkPb-MuiSnackbarContent-root {
    background: #d9534f;
  }

  .success .bkPb-MuiSnackbarContent-root {
    background: #72bb72;
  }

  .stage-skip {
  }

  .pane-build-section {
    .status-icon {
      margin: 7px 0 0;
    }

    .status-tag {
      padding: 0 5px 0 0;
    }
  }

  #message-id {
    align-items: center;
    line-height: 1.8;
    display: flex;
    max-width: 350px;
    white-space: pre-line;

    svg {
      vertical-align: middle;
      margin: 0 10px 0 0;
    }
  }

  .cta-btn {
    background: #eee;
    padding: 12px 15px;
    border-radius: 4px;
    font-family: Karla;
    letter-spacing: -1px;
    font-weight: 600;
    display: inline-block;
  }

  .stage-skip-btn {
    padding: 14px 37px 14px 0;
    position: relative;
    color: #bbb;
    background: none;
    font-size: 14px;
    border-radius: 4px;
    letter-spacing: -1px;
    font-weight: 600;
    display: inline-block;

    &:after {
      content: "\F04E";
      font-family: Line Awesome Free;
      position: absolute;
      right: 0;
      font-size: 17px;
      top: 6px;
      background: #eee;
      height: 30px;
      width: 30px;
      border-radius: 50px;
      line-height: 30px;
      text-align: center;
    }
  }

  .stage-skip-accent {
    display: block;
    padding: 2px 0 0;
  }

  .select2-more {
    text-align: center;
    margin: 10px 0 0;
    padding: 15px 0;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 500;
    letter-spacing: 1px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  /* New Scene3D App */
  /* #app-pb #scene3d canvas {height: 500px!important;} */
  /* #app-pb #scene3d div {
  height: 100%;
} */
  #appNavLoader {
    display: inline-block;
    position: absolute;
    width: 35px;
    height: 35px;
    top: calc(50% - 50px - 17.5px);
    /* right: calc(375px - 25px); */
    right: calc(50% - 17.5px);
  }

  .catalog #appNavLoader,
  .not-building #appNavLoader {
    top: calc(50vh - 70px - 17.5px);
    right: calc(50vw - 125px - 17.5px);
  }

  #appNavLoader {
    width: 35px;
    height: 35px;
    -webkit-animation: circle infinite 0.25s linear;
    -moz-animation: circle infinite 0.25s linear;
    -o-animation: circle infinite 0.25s linear;
    animation: circle infinite 0.25s linear;
    border: 1px solid #fff;
    border-top-color: transparent;
    border-radius: 100%;
  }
  @-webkit-keyframes circle {
    0% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes circle {
    0% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-o-keyframes circle {
    0% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes circle {
    0% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  /* Save and order Custom Build Add to Cart Recuztomize page only */
  #appPdfViewer {
    position: relative;
    // background: rgba(0, 0, 0, 0.07);
    // .ReactPDF__Page__canvas,
    // .ReactPDF__Page__textContent {
    // width: 100%!important;
    // height: auto!important;
    // background: rgba(0, 0, 0, 0.07);
    // }
    // .ReactPDF__Page__canvas {
    // .react-pdf__Document,
    .react-pdf__Page__svg {
      margin-left: auto;
      margin-right: auto;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    }
    // .ReactPDF__Document {
    .react-pdf__Document {
      margin: 0 0 25px;
    }

    #pdfNavigation {
      margin: 0 auto 25px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      letter-spacing: 1px;
      // font-size: 11px;
      // font-family: 'Karla';
      font-weight: bold;
      // letter-spacing: -1px;
      // color: #444;
      position: relative;
      z-index: 1;
      max-width: 600px;

      svg {
        font-size: 28px;
        // color: #333;
        cursor: pointer;
        position: relative;
        top: 2px;
      }

      .cta-download {
        display: flex;
        padding: 5px 0;
        font-weight: bold !important;
        align-items: center;
        position: absolute;
        left: 0;
        color: #d9534f;
        cursor: pointer;

        .cta-icon {
          font-size: 18px;
          margin-right: 10px;
        }
      }

      #pdfPagination {
        // font-family: 'Karla';
        // font-size: 14px;
        // letter-spacing: 1px;
        // position: relative;
        // padding-left: 4px;
        font-family: "Karla";
        font-size: 13px;
        letter-spacing: 1px;
        position: relative;
        padding-left: 4px;
      }
    }
  }
  // .build {
  //   iframe {
  //     height: 1155px;
  //   }
  //
  // }
  .cta-atc {
    /* padding: 20px 0 20px 80px; */
    // padding: 20px 0 20px 120px;
    // padding: 0;
    // margin: 0 0 30px;
    // position: relative;
    // background: #72bb72;
    // color: #fff;
    // border: 1px solid #72bb72;
    // display: flex;
    // flex-grow: 1;
    /* padding: 20px 0 20px 80px; */
    position: relative;
    /* background: #72bb72; */
    color: #fff;
    /* border: 1px solid #72bb72; */
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    transition: 0.2s ease all;

    #app_pbQuantity {
      margin-right: 4px;
      border: 1px solid #aaa;
    }

    .cta-accent {
      // font-family: Karla;
      // text-transform: uppercase;
      // font-weight: bold;
      // letter-spacing: 1px;
      // font-size: 11px;
      // margin: 8px 0 0;
      color: rgba(255, 255, 255, 0.5);
      font-size: 10px;
      letter-spacing: 1.3px;
    }

    .action {
      padding: 15px 70px;
      border: 1px solid #999;
      background: #999;
      text-align: center;
      cursor: pointer;
      transition: 0.2s ease all;
      position: relative;
    }

    &:hover {
      .action {
        background: #72bb72;
        border-color: #72bb72;
      }
    }
  }

  .cta-lg {
    display: block;
  }

  .cta-action-bar {
    display: flex;
    flex-direction: column;
    margin: 0 -10px;
    // > .cta-inline:first-child {
    //   // flex-grow: 1;
    // }
    // > .cta-inline:last-child {
    //     // width: 35%;
    // }
    .cta-inline {
      padding: 0 10px;
      margin-bottom: 4px;
      align-items: center;
      display: flex;
      flex-grow: 1;

      .title {
        font-size: 17px;
        font-weight: bold;
      }

      .cta-download {
        height: 100%;
        align-items: center;
        display: flex;
        flex-grow: 1;
      }
    }
  }
  // .build .cta-group {
  //   margin-bottom: 30px;
  // }
  .cta-rep {
    color: #999;
    overflow: auto;
    // margin-left: -10px;
    // margin-right: -10px;
    text-align: center;
    flex-grow: 1;
    align-self: stretch;
    justify-content: center;
    padding: 16px 10px;
    border: 1px solid rgb(68, 68, 68);
    border: 1px solid rgba(0, 0, 0, 0.3);
    font-weight: bold;
    font-size: 14px;

    a {
      color: #333;
      transition: 0.2s ease all;

      &:hover {
        color: #72bb72;
      }
    }
  }

  .atcPlaceholder {
    height: 70px;
    width: 100%;
  }

  .shopPlaceholder {
    margin-top: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding-top: 30px;
  }

  #product-component-1580352451891 {
    /* Shopify External BuyBtn Control */
    max-width: 320px !important;
    position: absolute !important;
    top: 671px !important;
    z-index: 9 !important;
    right: 60px !important;
  }
  // @media (min-width: 1400px) {
  //   #product-component-1580352451891 {
  //     /* Shopify External BuyBtn Control */
  //     max-width: 240px !important;
  //     position: absolute !important;
  //     top: 686px !important;
  //     z-index: 9 !important;
  //     right: 60px !important;
  //   }
  //   /* End new floating */
  // }
  // @media (min-width: 1750px) {
  //   .quantity input {
  //     width: 55px;
  //   }
  //
  //   .atc {
  //     padding: 20px 0 20px 80px;
  //   }
  //
  //   .atcPlaceholder {
  //     height: 120px;
  //   }
  //
  //   #product-component-1580352451891 {
  //     /* Shopify External BuyBtn Control */
  //     max-width: 240px !important;
  //     display: none!important;
  //   }
  //
  //   .shopPlaceholder > .cta-group {
  //     // width: calc(100% - 240px);
  //   }
  //
  //   .cta-group > a {
  //     width: calc(50% - 20px);
  //   }
  // }
  .cta-lg.cta-info {
    border: 1px solid #444;
    margin-bottom: 20px;
    padding: 30px 40px 40px;

    .cta-accent {
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.4);
    }

    .cta-title {
      font-family: Karla;
      text-transform: uppercase;
      font-size: 20px;
      color: #333;
    }
  }

  .cta-overlay {
    transition: 0.2s ease all;
    cursor: pointer;

    .cta-overlay-body {
      opacity: 0;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: hsla(0, 0%, 62%, 0.74);
      text-align: center;
      color: #fff;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: 0.2s all ease;
    }

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.3);
    }

    .cta-overlay-inner {
      // display: table-cell;
      // vertical-align: middle;
    }

    &:hover {
      .cta-overlay-body {
        opacity: 1;
      }

      .cta-accent,
      .cta-title {
        opacity: 0.2;
      }
    }
  }
  /* Summary & Save pages */
  .revitIcon {
    // background-image: url("../../Assets/Image/revitLogo.png");
    // background-size: 12px 14px;
    // background-color: #63b9e21f;
    // background-repeat: no-repeat;
    // background-position: 10px 7px;
    // height: 30px;
    // width: 30px;
    // top: 15px;
    // right: 15px;
    // position: absolute;
    // padding: 2px;
    // border-radius: 50%;
  }
  // #appPdfViewer > * {
  //   width: 100%;
  //   height: 800px;
  // }
  /* ThreeJS: fixing lacking div heights from threeJS app: might be interferring with bounding box? test */
  #scene3d .app {
    height: 100%;

    > div {
      height: 100%;

      > .three {
        height: 100%;
      }
    }
  }
  /* needs review end */

  .pb-filter-select__control {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
    border-radius: 3px;
    font-size: 14px;
  }
  .pb-filter-select__control--menu-is-open {
    // box-shadow: 0 2px 1px rgba(0,0,0,.05);
  }
  .pb-filter-select__value-container {
    padding-left: 15px;
  }
  .pb-filter-select__value-container--is-multi {
  }
  .pb-filter-select__placeholder {
  }
  .pb-filter-select__input-container {
  }
  .pb-filter-select__indicators {
  }
  .pb-filter-select__indicator-separator {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .pb-filter-select__indicator {
    padding: 0 5px;
  }
  .pb-filter-select__indicator svg {
    opacity: 0.9;
    width: 16px;
    height: 16px;
  }
  .pb-filter-select__menu {
    margin-top: 7px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  }
  .pb-filter-select__menu-list {
    padding: 5px 5px;
  }
  .pb-filter-select__option {
    background-color: transparent;
    color: #41444e;
    cursor: pointer;
    display: block;
    font-size: 12px;
    padding: 8px 10px;
    width: 100%;
    // border-radius: 2px;
    font-family: "Karla";
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .pb-filter-select__option:hover {
    background: #41444e;
    color: #fff;
  }
}

#content {
  padding: 0;
}

.ccnModalCartSuccess {
  .ccnModalCartSuccessInner {
    padding: 30px 40px !important;

    h3 {
      font-size: 20px;
      font-family: "Karla" !important;
      font-style: normal;
      text-transform: none;
      // padding-right: 20px;
    }

    .ccnSuccessLg {
      font-size: 60px;
      // margin-top: -6px;
      color: #72bb72;

      &:before {
        content: "\f058";
        font-family: "Line Awesome Free" !important;
      }
    }

    .ccnCartLg {
      font-size: 60px;
      margin-top: -16px;
      margin-left: -7px;
      color: #72bb72;

      &:before {
        content: "\f07a";
        font-family: "Line Awesome Free" !important;
        font-weight: 900;
      }
    }
  }

  .modalCartSuccessBody {
    margin: 0 0 30px;
    .imgBox {
      text-align: center;
      margin: 0 0 10px;
    }

    .bodyBox {
      text-align: left;
    }
  }

  .modalCartCheckoutBox {
    display: flex;
    width: 100%;
    text-align: center;

    .shopCheckout,
    .shopContinue {
      width: 50%;
      cursor: pointer;
    }

    .shopContinue {
      width: 50%;
      cursor: pointer;
      padding-right: 7px;
    }

    .shopCheckout {
      width: 50%;
      cursor: pointer;
      padding-left: 7px;
    }

    .shopContinue a {
      display: block;
      padding: 13px 0;
      border: 2px solid #222;
      font-weight: 600;
      background: #222;
      color: #fff;
      font-size: 14px;
      font-weight: bold;

      &:hover {
        background: #fff;
        color: #222;
      }
    }

    .shopCheckout a {
      display: block;
      padding: 13px 0;
      font-weight: 600;
      color: #72bb72;
      border: 2px solid #72bb72;
      font-size: 14px;
      font-weight: bold;

      &:hover {
        background: #72bb72;
        color: #fff;
      }
    }
  }
}
// #app-pb {
//   background: radial-gradient(ellipse at center top, rgb(189, 189, 189) 0%, rgb(101, 104, 109) 52%, rgb(85, 92, 101) 100%);
//   min-height: 100vh;
//   position: relative;
//   box-shadow: -4px 0 3px rgba(0, 0, 0, 0.1);
//   font-family: "Karla", "Open Sans", Arial, Helvetica, sans-serif;
//
//   > .fullscreen-enabled {
//     background: radial-gradient(ellipse at center top, rgb(189, 189, 189) 0%, rgb(101, 104, 109) 52%, rgb(85, 92, 101) 100%);
//     min-height: 100vh;
//     position: relative;
//     box-shadow: -4px 0 3px rgba(0, 0, 0, 0.1);
//     font-family: "Karla", "Open Sans", Arial, Helvetica, sans-serif;
//   }
// }
// .mode-light {
//   background: radial-gradient(ellipse at center top, rgb(255, 254, 254) 0%, rgb(198, 202, 208) 52%, rgb(127, 134, 144) 100%) !important;
//
//   > .fullscreen-enabled {
//     background: radial-gradient(ellipse at center top, rgb(255, 254, 254) 0%, rgb(198, 202, 208) 52%, rgb(127, 134, 144) 100%) !important;
//   }
// }

#app-pb {
}
/* product single */
/* Overriding @Drupal /MT/styles-bock.css */
.full-screen-helper .toggle-href {
  display: block !important;
}
/* Summary page */
.summary:not(.save) #toggleThreeContainer > .toggle-href:first-child {
  /* @Refactor: (potentially) Pull into React, overriding isFull state */
  display: none !important;
}
/* Save page */
.app-pb {
  // &.save > .fullscreen-enabled .three-container-inner{
  //   height: 100% !important;
  // }
  &.build .three-info-overlay .intro h1 > span:nth-child(2):before,
  &.summary .three-info-overlay .intro h1 > span:nth-child(2):before {
    content: "/";
    position: relative;
    padding: 0 10px;
  }
}
